import { fGetFunction, fPostFunction } from './firebase'

const baseApiUrl = "medicines"

export function getList() {
  // return fGetFunction(`${baseApiUrl}/get-list`)
  return Promise.resolve([
    { id: 1, description: "Amoxicilina" },
    { id: 2, description: "Aciclovir" },
    { id: 3, description: "Budesonida" },
    { id: 4, description: "Paracetamol" }
  ])
}

export function getItem(id) {
  return fGetFunction(`${baseApiUrl}/get-item`, {
    id: id
  })
}

export function createOrUpdate(data) {
  const action = data?.id?.length > 0 ? '/update-item' : '/add-item'
  return fPostFunction(`${baseApiUrl}${action}`, data)
}

export function remove(id) {
  return fPostFunction(`${baseApiUrl}/delete-item`, {
    id: id
  })
}
