import { format as dateFormat } from "date-fns";
import { times as _times } from "lodash";
import { datePlusMinutes, dateWithoutSecMs, dateWithoutTime } from ".";

export function isProduction() {
  return process.env.NODE_ENV && process.env.NODE_ENV === "production";
}

export function getLocalizedText(intl, id) {
  return intl.formatMessage({ id: id });
}

export function getEpoch(date = new Date()) {
  return date.getTime(); // UTC epoch
}

export function getPercentOfDay(date = new Date()) {
  let day = 12 * 60;
  let now = convertWithFormat(date, "hh") * 60 + date.getMinutes();

  return parseInt((now * 100) / day);
}

export function getFullMonthAndYear(date) {
  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  return `${meses[date.getMonth()]}/${date.getFullYear()}`;
}

export function getEpochStartOfTheDay(date = new Date(), utc = true) {
  return utc ? date.setUTCHours(0, 0, 0, 0) : date.setHours(0, 0, 0, 0);
}

export function getEpochEndOfTheDay(date = new Date(), utc = true) {
  return utc
      ? date.setUTCHours(23, 59, 59, 999)
      : date.setHours(23, 59, 59, 999);
}

export function getEpochDiff(olderDate, newerDate) {
  return newerDate - olderDate;
}

export function getDiffInMinutes(olderDate, newerDate) {
  return parseInt(getEpochDiff(olderDate, newerDate) / 60000);
}

export function getDiffInHours(olderDate, newerDate) {
  return parseInt(getDiffInMinutes(olderDate, newerDate) / 60);
}

export function getDiffInDays(olderDate, newerDate, ignoreTime = true) {
  if (ignoreTime) {
    olderDate = dateWithoutTime(olderDate);
    newerDate = dateWithoutTime(newerDate);
  }
  return parseInt(getDiffInHours(olderDate, newerDate) / 24);
}

export function getWeekdayName(intl, date, longName = false) {
  return getLocalizedText(
      intl,
      `WEEKDAY.${date.getDay()}${longName ? ".LONG" : ""}`
  );
}

export function convertMillisToSeconds(timestampInMillis) {
  return timestampInMillis / 1000;
}

export function convertMillisToMinutes(timestampInMillis) {
  return convertMillisToSeconds(timestampInMillis) / 60;
}

export function convertMillisToHours(timestampInMillis) {
  return convertMillisToMinutes(timestampInMillis) / 60;
}

export function convertSecondsToMillis(seconds) {
  return seconds * 1000;
}
export function convertMinutesToMillis(minutes) {
  return minutes * 60000;
}

export function getBooleanDescription(intl, value) {
  return value === undefined
      ? undefined
      : getLocalizedText(intl, `BOOLEAN.${value}`);
}

export function convertSecondsToDateTime(seconds) {
  return convertDateTimeToDMY(new Date(seconds * 1000));
}
export function convertSecondsToTime(seconds) {
  return convertDateTimeToTime(new Date(seconds * 1000));
}

export function removeDateSeparator(ymdDate) {
  return convertWithFormat(ymdDate, `yyyMMdd`);
}

export function convertDateToDMY(ymdDate, separator = "/") {
  return convertWithFormat(ymdDate, `dd${separator}MM${separator}yyy`);
}

export function convertDateTimeToDMY(ymdDate, separator = "/") {
  return convertWithFormat(
      ymdDate,
      `dd${separator}MM${separator}yyyy HH:mm:ss`
  );
}

export function convertDateTimeToTime(ymdDate) {
  return convertWithFormat(ymdDate, `HH:mm:ss`);
}

export function convertDateTimeToTimeNoSeconds(ymdDate) {
  return convertWithFormat(ymdDate, `HH:mm`);
}

export function getFormattedToday() {}

export function convertWithFormat(date, format) {
  if (!date) return "";

  return dateFormat(date, format);
}

export function timeElapsedInSeconds(timestampInSeconds) {
  const now = Math.floor(Date.now() / 1000);
  const old = new Date(timestampInSeconds).getTime();
  return now - old;
}

export function prettyElaspedTime(
    timestampInMillis,
    breakLine = false,
    intl = null
) {
  if (
      !timestampInMillis ||
      timestampInMillis.length === 0 ||
      timestampInMillis < 0
  )
    return "";

  let tsInSeconds = convertMillisToSeconds(timestampInMillis);
  let t = timeElapsedInSeconds(tsInSeconds);

  const oneHour = 3600;
  const twelveHours = 43200;
  // const twentyFourHours   = 86400
  // const fourtyEightHours  = 172800

  let msg = "?";
  if (t < 0) {
    // timestamp in future
    msg = `< 0s`;
  } else if (t < 60) {
    // seconds block
    msg = `< ${Math.ceil(t / 5)}s`; // (t/5) for chunks of 5 seconds {5..10..15..20}
  } else if (t < oneHour) {
    // minutes block
    msg = `< ${Math.ceil(t / 60)}m`;
  } else if (t >= oneHour && t <= twelveHours) {
    // hours block
    msg = `${Math.ceil(t / oneHour)}h`;
    // } else if (t > twelveHours && t <= twentyFourHours && intl) { // today block
    //   msg = `${intl.formatMessage({id: 'TODAY'}, {value: convertSecondsToTime(tsInSeconds)})}`
    // } else if (t > twentyFourHours && t <= fourtyEightHours && intl) { // yesterday block
    //   msg = `${intl.formatMessage({id: 'YESTERDAY'}, {value: convertSecondsToTime(tsInSeconds)})}`
  } else {
    msg = convertSecondsToDateTime(tsInSeconds);
  }

  return msg;
}

export function formatPhoneNumber(value) {
  let length = value?.trim().length;

  if (!length) return "";
  else if (length === 8)
    return `${value.substring(0, 4)}-${value.substring(4)}`;
  else if (length === 9)
    return `${value.substring(0, 5)}-${value.substring(5)}`;
  else if (length === 10)
    return `(${value.substring(0, 2)}) ${value.substring(
        2,
        6
    )}-${value.substring(6)}`;
  else if (length === 11)
    return `(${value.substring(0, 2)}) ${value.substring(
        2,
        7
    )}-${value.substring(7)}`;
  else return value;
}

export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomDouble(min, max) {
  return getRandomInt(min, max);
}

export function calculateTimeList(intervalInMinutes, countOfItems, fromTime) {
  const intervalController = intervalInMinutes ?? 30;
  const optionsCount = countOfItems ?? 4;

  const now = fromTime ?? new Date();
  const nowM = now.getMinutes();
  const nextM = Math.ceil(nowM / intervalController) * intervalController;
  const diff = nextM - nowM;

  const dates = [dateWithoutSecMs(datePlusMinutes(diff, now))];
  _times(optionsCount - 1, (_) =>
      dates.push(
          dateWithoutSecMs(
              datePlusMinutes(intervalController, dates[dates.length - 1])
          )
      )
  );
  return dates.map((val) => {
    return { id: val.getTime(), label: convertDateTimeToDMY(val) };
  });
}

export function calculateNextTimes(
    intervalInMinutes,
    countOfItems,
    fromTime,
    includeFromTime = false
) {
  const dates = [];

  if (includeFromTime) dates.push(dateWithoutSecMs(fromTime));

  if (!includeFromTime || countOfItems > 1)
    _times(countOfItems, (_) =>
        dates.push(
            dateWithoutSecMs(
                datePlusMinutes(
                    intervalInMinutes,
                    dates[dates.length - 1] ?? fromTime
                )
            )
        )
    );

  return dates.map((val) => {
    return { id: val.getTime(), label: convertDateTimeToDMY(val) };
  });
}