import React from "react";
import { injectIntl } from "react-intl"
import { BaseMultipleYAxis } from ".";
import { getEpoch } from "_metronic/_helpers";
import { BaseMultipleYAxisDataProps } from "./BaseMultipleYAxis";

export interface MonitoringUsedFeaturesMultipleYAxisData {
  total       : number
  braden      : Array<number>
  morse       : Array<number>
  lpps        : number
  allScales   : number
  allFeatures : number
  noFeatures  : number
}
export interface MonitoringUsedFeaturesMultipleYAxisProps {
  intl: any
  data?: MonitoringUsedFeaturesMultipleYAxisData
  width?: number
  height?: number
}
function MonitoringUsedFeaturesMultipleYAxis(props: MonitoringUsedFeaturesMultipleYAxisProps) {
  const { intl, data, width, height } = props
  const [newSignal, setNewSignal] = React.useState<number>(0)

  const xAxis = ["Funcionalidades"]
  function onDataSerie() : Array<BaseMultipleYAxisDataProps> | null {
    if (!data) return []

    const braden  = data.braden.filter((i) => i > 0) 
    const morse   = data.morse.filter((i) => i > 0) 

    return [
      {
        name: intl.formatMessage({ id: "REPORTS.FEATURE_USAGE.COLUMN.MONITORING" }),
        type: 'column',
        data: [data.total],
        color: "#008ffb"
      },
      {
        name: intl.formatMessage({ id: "REPORTS.FEATURE_USAGE.COLUMN.BRADEN" }),
        type: 'column',
        data: [braden],
        color: "#00e396"
      },
      {
        name: intl.formatMessage({ id: "REPORTS.FEATURE_USAGE.COLUMN.MORSE" }),
        type: 'column',
        data: [morse],
        color: "#feb019"
      },
      {
        name: intl.formatMessage({ id: "REPORTS.FEATURE_USAGE.COLUMN.LPPS" }),
        type: 'column',
        data: [data.lpps],
        color: "#ff4560"
      },
      {
        name: intl.formatMessage({ id: "REPORTS.FEATURE_USAGE.COLUMN.SCALES" }),
        type: 'column',
        data: [data.allScales],
        color: "#775dd0"
      },
      {
        name: intl.formatMessage({ id: "REPORTS.FEATURE_USAGE.COLUMN.ALL_FTS" }),
        type: 'column',
        data: [data.allFeatures],
        color: "#fb6100"
      },
      {
        name: intl.formatMessage({ id: "REPORTS.FEATURE_USAGE.COLUMN.NONE" }),
        type: 'column',
        data: [data.noFeatures],
        color: "#c3c3c3"
      }
    ] as Array<BaseMultipleYAxisDataProps>
  }

  React.useEffect(() => {
    setNewSignal(getEpoch())
  }, [data])

  return (
    <BaseMultipleYAxis
      width={width ?? '100%'} 
      height={height ?? '100%'}
      id='monitorings-used-features'
      title={intl.formatMessage({id: "REPORTS.FEATURE_USAGE.TITLE"})}
      sortTitle={intl.formatMessage({id: "REPORTS.FEATURE_USAGE.SORT.TITLE"})}
      modalTitleId={"REPORTS.FEATURE_USAGE.TITLE"}
      modalBodyTextId={"REPORTS.FEATURE_USAGE.DETAILS"}
      colors={[]}
      onDataSerie={onDataSerie}
      xAxis={xAxis}
      newDataSignal={newSignal}
      />
  )
}
export default injectIntl((MonitoringUsedFeaturesMultipleYAxis))
