import React from "react";
import { injectIntl } from "react-intl"
import { BaseDonut } from ".";
import { getEpoch } from "_metronic/_helpers";

export interface PCurrentMonitoringsData {
  active: number
  total: number
}
export interface PCurrentMonitoringsDataProps {
  intl: any
  data?: PCurrentMonitoringsData
  width?: number
  height?: number
}
function CurrentMonitoringsDonut(props: PCurrentMonitoringsDataProps) {
  const { intl, data, width, height } = props
  const [newSignal, setNewSignal] = React.useState<number>(0)

  const onDataSerie = () => {
    if (!data) return null
    return [data.active, (data.total - data.active)]
  }

  React.useEffect(() => {
    setNewSignal(getEpoch())
  }, [data])

  const chartTitle = intl.formatMessage({
    id: "CHART.PACIENTS_STATISTICS.MONITORING.TITLE",
  });

  return (
    <BaseDonut
      width={width ?? '100%'} 
      height={height ?? '100%'}
      id='current-monitorings'
      title={chartTitle}
      titleSort={chartTitle}
      colors={['#00E396D9', '#FE5819D9']}
      labels={['Ativo', 'Inativo']}
      onDataSerie={onDataSerie}
      newDataSignal={newSignal}
      /> 
  )
}
export default injectIntl((CurrentMonitoringsDonut))
