import React from "react";
import { injectIntl } from "react-intl"
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"
import { toAbsoluteUrl } from "_metronic/_helpers";

export interface PIllustratedPositionDurationChart {
  DDH: number
  DV:  number
  DLE: number
  DLD: number
  SED: number
}

function convertMinToHumanReadable(totalMins: number): string {
  if (isNaN(totalMins)) return ""

  let hr        = Math.floor(totalMins / 60)
  let mins      = Math.floor(totalMins % 60)

  return `${(hr > 0) ? `${hr}h` : ""} ${mins > 0 ? `${mins}min` : ""}`
}

export interface PIllustratedPositionDurationChartProps {
  intl: any
  data: PIllustratedPositionDurationChart
  width?: number
  height?: number
}
function IllustratedPositionDurationChart(props: PIllustratedPositionDurationChartProps) {
  const { data, width, height } = props

  let initialPaddings = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
  
  const paddings = {
    '350' : {
      top: 80,
      left: 70,
      right: 80,
      bottom: 120
    },
    '390' : {
      top: 70,
      left: 60,
      right: 80,
      bottom: 110
    },
    '500' : {
      top: 60,
      left: 50,
      right: 70,
      bottom: 100
    },
    '630' : {
      top: 50,
      left: 30,
      right: 60,
      bottom: 80
    },
    '990' : {
      top: 30,
      left: 15,
      right: 40,
      bottom: 60
    },
    '1400' : {
      top: 50,
      left: 50,
      right: 70,
      bottom: 90
    },
    '1800' : {
      top: 30,
      left: 20,
      right: 50,
      bottom: 80
    }
    ,
    '3000' : {
      top: 20,
      left: 10,
      right: 40,
      bottom: 70
    }
  }

  for (const [width, padding] of Object.entries(paddings)) {
    if (window.innerWidth <= Number(width)) {
      initialPaddings = padding;
      break;
    }
  }

  const [options] = React.useState<ApexOptions>({
    chart: {
      id: 'apex', 
    },
    title: {
      text: "Permanência por Decúbito",
      align: 'left',
    },
    grid: {
      padding: initialPaddings,
    },
    noData: {
      style: {
        color: "red"
      },
      text: "Nothing"
    },
    plotOptions: {
      polarArea: {
        rings: {
          strokeWidth: 1,
        }
      }
    },
    stroke:{
      colors:['#0000000']
     },
    labels: ['Dorsal', 'Ventral', 'Lateral Esquerdo', 'Lateral Direito', 'Sedestação'],
    dataLabels: {
      enabled: true,
      textAnchor: 'end',
      formatter: (value, { seriesIndex, w }) => convertMinToHumanReadable(w.config.series[seriesIndex]),
    },
    tooltip: { 
      enabled: true,
      y: {
        formatter: (value) => convertMinToHumanReadable(value)
      }
    },
    legend: {
      show: false
    },
    yaxis: {
      show: false
    },
    responsive: [
      {
        breakpoint: 350,
        options: {
          grid: {
            padding: paddings['350'],
          }
        }
      },
      {
        breakpoint: 390,
        options: {
          grid: {
            padding: paddings['390'],
          }
        }
      },
      {
        breakpoint: 500,
        options: {
          grid: {
            padding: paddings['500'],
          }
        }
      },
      {
        breakpoint: 630,
        options: {
          grid: {
            padding: paddings['630'],
          }
        }
      },
      {
        breakpoint: 990,
        options: {
          grid: {
            padding: paddings['990'],
          }
        }
      },
      {
        breakpoint: 1400,
        options: {
          grid: {
            padding: paddings['1400'],
          }
        }
      },
      {
        breakpoint: 1800,
        options: {
          grid: {
            padding: paddings['1800'],
          }
        }
      },
      {
        breakpoint: 3000,
        options: {
          grid: {
            padding: paddings['3000'],
          }
        }
      },
      {
        breakpoint: 9999,
        options: {
          grid: {
            padding: {
              top: 30,
              left: 20,
              right: 50,
              bottom: 80
            },
          }
        }
      },
    ]
  })

  const [dataSerie, setDataSerie] = React.useState<Array<number>>([0,0,0,0,0])
  React.useEffect(() => {
    setDataSerie([data.DDH,data.DV,data.DLE,data.DLD,data.SED])
  }, [data])

  return (
    <div style={{
      backgroundRepeat:'no-repeat', 
      backgroundSize:'contain', 
      backgroundPosition: 'center',
      backgroundImage: `url(${toAbsoluteUrl("/media/misc/illustrated-positions.svg")})`}}>
      <Chart
        type="polarArea"
        options={options} 
        series={dataSerie} 
        width={width ?? '100%'} 
        height={height ?? '100%'} 
        />
    </div>
  )
}
export default injectIntl((IllustratedPositionDurationChart))
