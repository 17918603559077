import { logToSentry } from "./SentryHelper";

export interface IDetailedDuration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
export function getDetailedDuration(
  durationInMillis: number
): IDetailedDuration | null {
  if (!durationInMillis || durationInMillis <= 0) return null;

  var seconds = Math.floor(durationInMillis / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

  return { days, hours, minutes, seconds } as IDetailedDuration;
}

export function epochToTimezone(
  epoch: number | string,
  timezoneOffset?: number | undefined
): number {
  if (!timezoneOffset) timezoneOffset = new Date().getTimezoneOffset();

  if (typeof epoch === "string") epoch = parseInt(`${epoch}`);

  return epoch - timezoneOffset * 60000;
}
export const dateWithoutSecMs = (date = new Date()) => {
  date.setSeconds(0, 0);
  return date;
};

export const datePlusSeconds = (seconds: number, date = new Date()) =>
  new Date(date.getTime() + seconds * 1000);
export const dateMinusSeconds = (seconds: number, date = new Date()) =>
  new Date(date.getTime() - seconds * 1000);

export const datePlusMinutes = (minutes: number, date = new Date()) =>
  datePlusSeconds(minutes * 60, date);
export const dateMinusMinutes = (minutes: number, date = new Date()) =>
  dateMinusSeconds(minutes * 60, date);

export const datePlusHours = (hours: number, date = new Date()) =>
  datePlusMinutes(hours * 60, date);
export const dateMinusHours = (hours: number, date = new Date()) =>
  dateMinusMinutes(hours * 60, date);

export const datePlusDays = (days: number, date = new Date()) =>
  datePlusHours(days * 24, date);
export const dateMinusDays = (days: number, date = new Date()) =>
  dateMinusHours(days * 24, date);

export const dateWithoutTime = (date = new Date()) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const dateSetStartOfTheDay = (date = new Date()) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
export const dateSetEndOfTheDay = (date = new Date()) =>
  new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
    999
  );

export const convertStringBrDateToAmerican = (
  birthDate: string,
  separator: string = "/"
): string => {
  return birthDate
    .split(separator)
    .reverse()
    .join(separator);
};

export const getAge = (birthDate: Date): number => {
  var today = new Date();
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export function isValidDate(dateString: string | undefined | null) {
  if (!dateString) return false;
  // Verifica se a string da data está no formato DD/MM/AAAA
  const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

  if (!datePattern.test(dateString)) {
    return false;
  }

  // Extrai o dia, mês e ano da string da data
  const [day, month, year] = dateString.split("/").map(Number);

  // Cria um objeto Date com os valores extraídos
  const date = new Date(year, month - 1, day);

  // Verifica se a data é válida
  return (
    date.getDate() === day &&
    date.getMonth() === month - 1 &&
    date.getFullYear() === year
  );
}

export const normalizeText = (
  text: String,
  form: "NFC" | "NFD" | "NFKC" | "NFKD" = "NFD"
): String => {
  return text.normalize(form).replace(/[\u0300-\u036f]/g, "");
};

export const getLocalizedError = (
  intl: any,
  id: string | undefined,
  errorCode?: any
): string => {
  const code = errorCode ?? "internal";

  if (`${code}`.toLocaleLowerCase().localeCompare("internal"))
    id = `CODE.internal`;
  else id = `${id}.${code}`;
  if (!!!intl.messages[id]) {
    // not exists
    logToSentry(new Error("Translation not found"), id);
    id = `CODE`;
  }

  return intl.formatMessage({
    id: id,
  });
};

// Validates a Brazilian CPF number.
export const isValidCPF = (cpf: string): boolean => {
  // Remove any non-numeric characters and validate length
  const formattedCPF = cpf.replace(/[^\d]/g, "");
  if (formattedCPF.length !== 11) {
    return false;
  }

  // Calculate check digit using the specified factor
  const calculateCheckDigit = (cpf: string, factor: number): number => {
    let sum = 0;
    for (let i = 0; i < factor - 1; i++) {
      sum += parseInt(cpf[i], 10) * (factor - i);
    }
    const remainder = sum % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  // Check if all digits are the same
  const isSequentialDigits = (cpf: string): boolean => {
    return cpf.split("").every((digit) => digit === cpf[0]);
  };

  // Return false if all digits are the same (invalid CPF)
  if (isSequentialDigits(formattedCPF)) {
    return false;
  }

  // Compute both check digits
  const checkDigit1 = calculateCheckDigit(formattedCPF, 10);
  const checkDigit2 = calculateCheckDigit(formattedCPF, 11);

  // Validate computed check digits against provided ones
  return (
    checkDigit1 === parseInt(formattedCPF[9], 10) &&
    checkDigit2 === parseInt(formattedCPF[10], 10)
  );
};
