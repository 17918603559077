import React from "react";
import { FormattedMessage } from "react-intl";
import {
  Col,
  Form,
  ToggleButtonGroup,
  ToggleButton
} from "react-bootstrap";
import {
  FormControlLabel, Typography
} from "@material-ui/core"
import { get as _get } from "lodash"

export interface FormButtonSwitchProps {
  intl                  : any,
  lg?                   : number,
  xs?                   : number,
  titleId?              : string,
  plainTitle?           : string,
  commentId?            : string,
  tooltipId?            : string,
  labelPos?             : any,
  valueRef              : string,
  values                : any,
  setFieldValue?        : any,
  spaceBetween?         : boolean,
  flexDirectionColumn?  : boolean
}
export const FormButtonSwitch = (props: FormButtonSwitchProps) => {
  const { intl, lg, xs, titleId, plainTitle, commentId, tooltipId, valueRef, labelPos, values, setFieldValue, spaceBetween, flexDirectionColumn } = props

  const isLabelTop = labelPos === 'top'

  let minWidthLabel = 0

  if(titleId)
    minWidthLabel = intl.formatMessage({id:titleId}).length * 8

  return (
    <Form.Group as={Col} xs={xs ?? 6} lg={lg ?? 3}>
      <FormControlLabel
      style={{ 
        ...{ margin:0, alignItems: (isLabelTop) ? 'self-start' : 'center' },
        ...( spaceBetween ? { display: "flex", justifyContent: 'space-between' } : {} ),
        ...( flexDirectionColumn ? { display: "flex", flexDirection: 'column-reverse' } : {} )
      }}
      control={
        <ToggleButtonGroup
          className={ (isLabelTop) ? 'mt-3' : flexDirectionColumn ? 'ml-0' : 'ml-3' }
          type={"radio"}
          key={valueRef}
          name={valueRef}
          id={valueRef}
          onChange={(val: any) => setFieldValue(valueRef, val)}
          value={_get(values, valueRef)}
          >
          <ToggleButton size="sm" variant="outline-danger" type="radio" name="radio" value="0">
            { intl.formatMessage({id: "BOOLEAN.FALSE"}) }
          </ToggleButton>
          <ToggleButton size="sm" variant="outline-success" type="radio" name="radio" value="1">
            { intl.formatMessage({id: "BOOLEAN.TRUE"}) }
          </ToggleButton>
        </ToggleButtonGroup>
        }
      title={tooltipId && intl.formatMessage({id: tooltipId})}
      labelPlacement={labelPos ?? "end"}
      label={
        <Typography variant="body1" style={{ minWidth: `${minWidthLabel}px`}}>
          {(plainTitle) ? plainTitle : intl.formatMessage({id: titleId})}
        </Typography>
      }
      />
      { (commentId) && <Form.Label className="d-block mt-2"  style={{color:'#999', fontSize:11}}><FormattedMessage id={commentId} /></Form.Label> }
    </Form.Group>
  )
}
