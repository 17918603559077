import React from "react";
import { injectIntl } from "react-intl"
import { BaseDistributedColumn } from ".";
import { getEpoch, morseScaleRangeStyles } from "_metronic/_helpers";

export interface MorseMonitoringsDistributedColumnData {
  scales: Array<number>
  total: number
}
export interface MorseMonitoringsDistributedColumnProps {
  intl: any
  data?: MorseMonitoringsDistributedColumnData
  width?: number
  height?: number
}
function MorseMonitoringsDistributedColumn(props: MorseMonitoringsDistributedColumnProps) {
  const { intl, data, width, height } = props
  const [newSignal, setNewSignal] = React.useState<number>(0)
  
  const [labels] = React.useState<Array<string>>([
    intl.formatMessage({ id: "REPORTS.MORSE.RANGE.EMPTY" }),
    intl.formatMessage({ id: "REPORTS.MORSE.RANGE.LOW" }),
    intl.formatMessage({ id: "REPORTS.MORSE.RANGE.MEDIUM" }),
    intl.formatMessage({ id: "REPORTS.MORSE.RANGE.HIGH" })
  ])
  const [colors] = React.useState<Array<string>>([
    '#575757',
    '#19fe40',
    '#e6ec1c',
    '#fe1919'
  ])

  const onDataSerie = () => {
    if (!data) return null

    return data?.scales.reduce((p, c) => {
      const scaleInfo = morseScaleRangeStyles(c)
      
      p[scaleInfo.index]++
      return p
    }, new Array(labels.length).fill(0))
  }

  React.useEffect(() => {
    setNewSignal(getEpoch())
  }, [data])

  return (
    <BaseDistributedColumn
      width={width ?? '100%'} 
      height={height ?? '100%'}
      id='morse-monitorings'
      title={intl.formatMessage({id: "REPORTS.MORSE.RANGE.TITLE"})}
      sortTitle={intl.formatMessage({id: "REPORTS.MORSE.RANGE.SORT.TITLE"})}
      modalTitleId={"REPORTS.MORSE.RANGE.TITLE"}
      modalBodyTextId={"REPORTS.MORSE.RANGE.DETAILS"}
      colors={colors}
      labels={labels}
      onDataSerie={onDataSerie}
      newDataSignal={newSignal}
      />
  )
}
export default injectIntl((MorseMonitoringsDistributedColumn))
