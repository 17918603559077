import React, { useState } from "react";
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"
import { ModalInfo } from "../Globals";
import { getEpoch } from "_metronic/_helpers";

export interface BaseDistributedColumnProps {
  width?: number | string
  height?: number | string
  id: string
  title: string
  sortTitle?: string
  modalTitleId: string
  modalBodyTextId: string
  colors: Array<string>
  labels: Array<string>
  onDataSerie: () => Array<number> | null
  newDataSignal: number
}
function BaseDistributedColumn(props: BaseDistributedColumnProps) {
  const { width, height, id, title, sortTitle, modalTitleId, modalBodyTextId, colors, labels, onDataSerie, newDataSignal } = props
  const [showSignal, setShowSignal] = useState<number>(0)

  const [options] = React.useState<ApexOptions>({
    chart: {
      id: id,
      toolbar: {
        show: true,
        tools: {
          customIcons: [
            {
              index: 0,
              icon: "",
              class: "flaticon-questions-circular-button icon-lg ml-3",
              title: "Billy",
              click: () => setShowSignal(getEpoch())
            }
          ]
        }
      }
    },
    title: {
      text: title,
      align: 'left',
      style: {

      }
    },
    noData: {
      style: {
        color: "red"
      },
      text: "Nothing"
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
        dataLabels: {
          position: "top"
        }
      }
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      offsetY: -20,
      style: {
        colors: ["black"]
      }
    },
    xaxis: {
      categories: labels,
      labels: {
        show: false,
        style: {
          colors: colors,
          fontSize: '12px'
        },
      }
    },
    colors: colors,
    tooltip: { 
      enabled: true,
    },
    legend: {
      horizontalAlign: 'left',
      labels: {

      }
    },
    responsive: [
      { 
        breakpoint: 340,
        options: {
          title: {
            text: sortTitle ?? title,
            align: 'left',
            style: {
              fontSize: '12px'
            }
          }
        }
      },
      { 
        breakpoint: 990,
        options: {
          title: {
            text: title,
            align: 'left',
            style: {
              fontSize: '12px'
            }
          }
        }
      },
      { 
        breakpoint: 1100,
        options: {
          title: {
            text: sortTitle ?? title,
            align: 'left',
            style: {
              fontSize: '12px'
            }
          }
        }
      },
      { 
        breakpoint: 1200,
        options: {
          title: {
            text: title,
            align: 'left',
            style: {
              fontSize: '8px'
            }
          }
        }
      },
      { 
        breakpoint: 1300,
        options: {
          title: {
            text: title,
            align: 'left',
            style: {
              fontSize: '10px'
            }
          }
        }
      },
      { 
        breakpoint: 1400,
        options: {
          title: {
            text: title,
            align: 'left',
            style: {
              fontSize: '12px'
            }
          }
        }
      },
      { 
        breakpoint: 99999,
        options: {
          title: {
            text: title,
            align: 'left',
            style: {
              fontSize: '12px'
            }
          }
        }
      },
    ]
  })

  const [dataSerie, setDataSerie] = React.useState<Array<any>>([])
  React.useEffect(() => {
    const data = onDataSerie()
    if (!data) return

    setDataSerie([{ data: data }])
  }, [newDataSignal, onDataSerie])

  return (
    <>
      <ModalInfo 
        showSignal={showSignal}
        titleId={modalTitleId}
        bodyTextId={modalBodyTextId}
        />
      <Chart
        type="bar"
        options={options} 
        series={dataSerie} 
        width={width ?? '100%'} 
        height={height ?? '100%'} 
        />
    </>
  )
}
export default (BaseDistributedColumn)
