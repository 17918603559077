import { FormattedMessage, injectIntl } from "react-intl";
import React from "react";
import { Table } from "react-bootstrap";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import IPatientDataInNotices from "../interfaces/IPatientDataInNotices";

const SOTableHeader = () => {
  return (
    <TableHead>
      <TableRow className="text-left">
        <TableCell style={{ width: "35%", fontSize: '10px' }} className="pl-0"><FormattedMessage id="PATIENTS.WARNINGS.NAME" /></TableCell>
        <TableCell style={{ width: "20%", fontSize: '10px', textAlign: 'center' }}><FormattedMessage id="PATIENTS.WARNINGS.MEDICAL.RECORD" /></TableCell>
        <TableCell style={{ width: "20%", textAlign: 'center', fontSize: '10px' }}><FormattedMessage id="PATIENTS.WARNINGS.LOCATION" /></TableCell>
        <TableCell style={{ width: "25%", textAlign: 'left', fontSize: '10px' }}><FormattedMessage id="PATIENTS.WARNINGS.SECTOR" /></TableCell>
      </TableRow>
    </TableHead>
  )
}

const SOTableRows = ({ rows }: any) => {
  if (!rows || rows.length === 0) return null

  return (
    <TableBody>
      {
        rows.map((obj: IPatientDataInNotices, idx: any) => {
          return (
            <TableRow key={idx} className="cursor-pointer">
              <TableCell>
                <span className="text-dark-75 font-weight-bolder mb-1 font-size-lg" >
                  {  obj.patientName }
                </span>
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                { obj.medicalRecord }
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>
                { obj.location }
              </TableCell>
              <TableCell style={{ textAlign: 'left' }}>
                { obj.sectorName }
              </TableCell>
            </TableRow>
          )
        })
      }
    </TableBody>
  )
}

function TablePatientWarnings(props: any) {
  return (
    <div className="table-responsive">
      <Table
        className="table table-head-custom table-vertical-center"
        id="kt_advance_table_widget_1"
        style={{ minWidth: '800px' }}
      >
        <SOTableHeader/>
        <SOTableRows 
          {...props} 
          />
      </Table>
    </div>
  )
}

export default injectIntl((TablePatientWarnings))