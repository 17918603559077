import { BaseModel } from "./base"

export interface IPacient {
  id: string
  name: string
  socialName: string
  medicalRecord: string
  location: string
  birthday: string
  sex?: string | null
  civil_state?: string | null
  phone:string
  zip_code: string
  address: string
  complement: string
  number: number
  neighborhood: string
  city: string
  state: string
  rg: string
  cpf: string
  description?: string | null
  sectorId?: string | null
  deviceId?: string | null
  monitoringId?: string | null
}

export interface IPacientMonitoring {
  sectorId?: string | null
  deviceId?: string | null
  monitoringId?: string | null
}

export class Pacient extends BaseModel implements IPacient, IPacientMonitoring {
  readonly id: string
  readonly name: string
  readonly socialName: string
  readonly medicalRecord: string
  readonly location: string
  readonly birthday: string
  readonly sex?: string | null
  readonly civil_state?: string | null
  readonly phone: string
  readonly zip_code: string
  readonly address: string
  readonly complement: string
  readonly number: number
  readonly neighborhood: string
  readonly city: string
  readonly state: string
  readonly rg: string
  readonly cpf: string
  readonly description?: string | null
  readonly deviceId?: string | null
  readonly sectorId?: string | null
  readonly monitoringId?: string | null

  constructor(args: IPacient) {
    super()

    this.id                = args.id
    this.name              = args.name ?? ""
    this.socialName        = args.socialName ?? ""
    this.medicalRecord     = args.medicalRecord
    this.location          = args.location
    this.birthday          = args.birthday ?? null
    this.sex               = args.sex ?? null
    this.civil_state       = args.civil_state ?? null
    this.phone             = args.phone ?? null
    this.zip_code          = args.zip_code ?? null
    this.address           = args.address ?? null
    this.complement       = args.complement ?? null
    this.number            = args.number ?? null
    this.neighborhood      = args.neighborhood ?? null
    this.city              = args.city ?? null
    this.state             = args.state ?? null
    this.rg                = args.rg ?? null
    this.cpf               = args.cpf ?? null
    this.description       = args.description ?? null
    this.sectorId          = args.sectorId ?? null
    this.deviceId          = args.deviceId ?? null
    this.monitoringId      = args.monitoringId ?? null
  }

  getFormattedName(): string | undefined {
    const _name          = (this.name ?? "")
    const _medicalRecord = (this.medicalRecord ? `${this.medicalRecord} - ` : undefined) ?? ""
    const formattedName  = _medicalRecord + _name
    
    return (formattedName.length > 0) ? formattedName : undefined
  }
}
