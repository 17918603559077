/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { MenuItem } from "."
import { 
  pacientsPath,
  medicinesPath,
  devicesPath,
  sectorsPath,
  wifisPath,
  usersPath
} from "api/endpoints"
import { FormattedMessage, injectIntl } from "react-intl";

function ManagementSection(props) {
  const { intl } = props
  const cProps = {
    intl: intl,
    display: true,
    parentTitleId: "MENU.MANAGEMENT"
  }

  return (
      <>
        <li className="menu-section ">
          <h4 className="menu-text"><FormattedMessage id="MENU.MANAGEMENT" /></h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <MenuItem 
          {...cProps}
          titleId={"MENU.MANAGEMENT.PACIENTS"}
          url={pacientsPath}
          icon="/media/svg/icons/Communication/Address-card.svg"
          />
        <MenuItem 
          {...cProps}
          titleId={"MENU.MANAGEMENT.MEDICINES"}
          url={medicinesPath}
          icon="/media/svg/icons/Medical/Medicine.svg"
          display={false}
          />
        <MenuItem 
          {...cProps}
          titleId={"MENU.MANAGEMENT.DEVICES"}
          url={devicesPath}
          icon="/media/svg/icons/Devices/Router2.svg"
          />
        <MenuItem 
          {...cProps}
          titleId={"MENU.MANAGEMENT.SECTORS"}
          url={sectorsPath}
          icon="/media/svg/icons/Home/Building.svg"
          />
        <MenuItem 
          {...cProps}
          titleId={"MENU.MANAGEMENT.WIFIS"}
          url={wifisPath}
          icon="/media/svg/icons/Devices/WiFi.svg"
          />
        <MenuItem 
          {...cProps}
          titleId={"MENU.MANAGEMENT.USERS"}
          url={usersPath}
          icon="/media/svg/icons/Communication/Group.svg"
          />

      </>
  );
}

export default injectIntl(ManagementSection)
